import axios from 'src/utils/axios';
import imageToBlob from 'src/utils/imageToBlob';

export const postItemApi = async (newItemInfo) => {
  try {
    const formData = new FormData();
    formData.append('name', newItemInfo.name);
    formData.append('itemTypeId', newItemInfo.itemTypeId);

    if (newItemInfo.unitId) formData.append('unitId', newItemInfo.unitId);
    if (newItemInfo.countryId)
      formData.append('countryId', newItemInfo.countryId);
    if (newItemInfo.cost) formData.append('cost', newItemInfo.cost);
    if (newItemInfo.sellPrice)
      formData.append('sellPrice', newItemInfo.sellPrice);
    if (newItemInfo.description)
      formData.append('description', newItemInfo.description);
    if (newItemInfo.itemImage.length) {
      newItemInfo.itemImage.forEach((eachImageData) => {
        formData.append(
          'itemImage',
          imageToBlob(eachImageData.image),
          eachImageData.name,
        );
      });
    }

    const res = await axios.post('edmu/mes/item', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const getAllItemsApi = async () => {
  try {
    const res = await axios.get('edmu/mes/item');
    return res;
  } catch (error) {
    return error;
  }
};

export const getItemInforApi = async (getItemInfo) => {
  try {
    const res = await axios.get('edmu/mes/item', {
      params: {
        id: getItemInfo.id || undefined,
        'item-type-id': getItemInfo.itemTypeId || undefined,
        'unit-id': getItemInfo.unitId || undefined,
        'country-id': getItemInfo.countryId || undefined,
        name: getItemInfo.name || undefined,
        code: getItemInfo.code || undefined,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const patchItemApi = async (modifyItemInfo) => {
  try {
    const formData = new FormData();
    formData.append('name', modifyItemInfo.data.name);
    formData.append('itemTypeId', modifyItemInfo.data.itemTypeId);

    if (modifyItemInfo.data.unitId)
      formData.append('unitId', modifyItemInfo.data.unitId);
    if (modifyItemInfo.data.countryId)
      formData.append('countryId', modifyItemInfo.data.countryId);
    if (modifyItemInfo.data.cost)
      formData.append('cost', modifyItemInfo.data.cost);
    if (modifyItemInfo.data.sellPrice)
      formData.append('sellPrice', modifyItemInfo.data.sellPrice);
    if (modifyItemInfo.data.description)
      formData.append('description', modifyItemInfo.data.description);
    if (modifyItemInfo.isEditImage) {
      modifyItemInfo.data.itemImage.forEach((eachImageData) => {
        formData.append(
          'itemImage',
          imageToBlob(eachImageData.image),
          eachImageData.name,
        );
      });
    }

    const res = await axios.patch(
      `edmu/mes/item?id=${modifyItemInfo.id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return res;
  } catch (error) {
    return error;
  }
};

export const deleteItemApi = async (deleteItemId) => {
  const res = await axios.delete(`edmu/mes/item?id=${deleteItemId}`);
  return res;
};

export const postItemTypeApi = async (newItemType) => {
  try {
    const res = await axios.post(`edmu/mes/item-type`, {
      name: newItemType.name,
      description: newItemType.description,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getItemTypeApi = async () => {
  try {
    const res = await axios.get('edmu/mes/item-type');
    return res;
  } catch (error) {
    return error;
  }
};

export const patchItemTypeApi = async (modifyItemTypeInfo) => {
  try {
    const res = await axios.patch(
      `edmu/mes/item-type?id=${modifyItemTypeInfo.id}`,
      modifyItemTypeInfo.data,
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteItemTypeApi = async (deleteItemTypeId) => {
  const res = await axios.delete(`edmu/mes/item-type?id=${deleteItemTypeId}`);
  return res;
};

export const postItemUnitApi = async (newItemUnit) => {
  try {
    const res = await axios.post(`edmu/mes/unit`, {
      name: newItemUnit.name,
      description: newItemUnit.description,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getItemUnitApi = async () => {
  try {
    const res = await axios.get('edmu/mes/unit');
    return res;
  } catch (error) {
    return error;
  }
};

export const patchItemUnitApi = async (modifyItemUnitInfo) => {
  try {
    const res = await axios.patch(
      `edmu/mes/unit?id=${modifyItemUnitInfo.id}`,
      modifyItemUnitInfo.data,
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteItemUnitApi = async (deleteItemUnitId) => {
  const res = await axios.delete(`edmu/mes/unit?id=${deleteItemUnitId}`);
  return res;
};

export const postItemCountryApi = async (newItemCountry) => {
  try {
    const res = await axios.post(`edmu/mes/country`, {
      name: newItemCountry.name,
      description: newItemCountry.description,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getItemCountryApi = async () => {
  try {
    const res = await axios.get('edmu/mes/country');
    return res;
  } catch (error) {
    return error;
  }
};

export const patchItemCountryApi = async (modifyItemCountryInfo) => {
  try {
    const res = await axios.patch(
      `edmu/mes/country?id=${modifyItemCountryInfo.id}`,
      modifyItemCountryInfo.data,
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteItemCountryApi = async (deleteItemCountryId) => {
  const res = await axios.delete(`edmu/mes/country?id=${deleteItemCountryId}`);
  return res;
};
