import { makeAutoObservable, runInAction } from 'mobx';
import { deleteImageApi } from 'src/api/image';
import {
  deleteItemApi,
  deleteItemCountryApi,
  deleteItemTypeApi,
  deleteItemUnitApi,
  getAllItemsApi,
  getItemCountryApi,
  getItemInforApi,
  getItemTypeApi,
  getItemUnitApi,
  patchItemApi,
  postItemApi,
  postItemCountryApi,
  postItemTypeApi,
  postItemUnitApi,
} from 'src/api/mes';

class mesStore {
  alertStore;

  items = [];

  itemInfor = {
    id: null,
    code: null,
    name: null,
    itemType: {
      id: null,
      name: null,
    },
    unit: {
      id: null,
      name: null,
    },
    country: {
      id: null,
      name: null,
    },
    cost: null,
    sellPrice: null,
    description: null,
    image: {
      id: null,
      imageUrl: null,
      filePath: null,
    },
  };

  itemType = [];

  itemUnit = [];

  itemCountry = [];

  constructor({ alertStore }) {
    makeAutoObservable(this);
    this.alertStore = alertStore;
  }

  createNewItem = async (newItemInfo) => {
    try {
      const res = await postItemApi(newItemInfo);
      if (res.code) throw res;
      this.alertStore.setAlertOpen('success', '품목 추가에 성공하였습니다');
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getAllItems = async () => {
    try {
      const res = await getAllItemsApi();
      if (res.code) throw res;
      this.items = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getItemInfor = async (getItemInfo) => {
    try {
      const res = await getItemInforApi(getItemInfo);
      if (res.code) throw res;
      this.itemInfor = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  initItemInfor = async () => {
    this.itemInfor = {
      id: null,
      code: null,
      name: null,
      itemType: {
        id: null,
        name: null,
      },
      unit: {
        id: null,
        name: null,
      },
      country: {
        id: null,
        name: null,
      },
      cost: null,
      sellPrice: null,
      description: null,
      image: {
        id: null,
        imageUrl: null,
        filePath: null,
      },
    };
  };

  // eslint-disable-next-line class-methods-use-this
  deleteItemImageById = async (selectedItemImages) => {
    try {
      console.log(selectedItemImages);

      const deleteItemImages = selectedItemImages.map((imageInfo) => {
        return deleteImageApi(imageInfo.id);
      });
      return Promise.all(deleteItemImages)
        .then(() => {
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  updateItemInfor = async (modifyItemInfo) => {
    try {
      if (modifyItemInfo.isEditImage && modifyItemInfo.registeredImageId) {
        const imageDeleteRes = await this.deleteItemImageById([
          { id: modifyItemInfo.registeredImageId },
        ]);
        if (imageDeleteRes !== true) throw imageDeleteRes;
      }
      const res = await patchItemApi(modifyItemInfo);
      if (res.code) throw res;
      this.alertStore.setAlertOpen('success', '품목 수정에 성공하였습니다');
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deleteItemsById = async (selecteditmes) => {
    try {
      const deleteItemTasks = selecteditmes.map((itemInfo) => {
        return deleteItemApi(itemInfo.id);
      });
      return Promise.all(deleteItemTasks)
        .then(() => {
          this.getAllItems();
          this.alertStore.setAlertOpen('success', `품목 삭제를 완료했습니다.`);
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  createNewItemType = async (newItemInfo) => {
    try {
      const res = await postItemTypeApi(newItemInfo);
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '품목 종류 추가에 성공하였습니다',
      );
      this.getItemType();
      return res.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getItemType = async () => {
    try {
      const res = await getItemTypeApi();
      if (res.code) throw res;
      this.itemType = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  updateItemType = async (getItemInfo) => {
    try {
      const res = await patchItemApi(getItemInfo);
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deleteItemType = async (deleteItemTypeId) => {
    try {
      const res = await deleteItemTypeApi(deleteItemTypeId);
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '품목 종류 삭제에 성공하였습니다',
      );
      this.getItemType();
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  createNewItemUnit = async (newItemInfo) => {
    try {
      const res = await postItemUnitApi(newItemInfo);
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '품목 단위 추가에 성공하였습니다',
      );
      this.getItemUnit();
      return res.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getItemUnit = async () => {
    try {
      const res = await getItemUnitApi();
      if (res.code) throw res;
      this.itemUnit = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  updateItemUnit = async (getItemInfo) => {
    try {
      const res = await patchItemApi(getItemInfo);
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deleteItemUnit = async (deleteItemUnitId) => {
    try {
      const res = await deleteItemUnitApi(deleteItemUnitId);
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '품목 단위 삭제에 성공하였습니다',
      );
      this.getItemUnit();
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  createNewItemCountry = async (newItemInfo) => {
    try {
      const res = await postItemCountryApi(newItemInfo);
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '품목 단위 추가에 성공하였습니다',
      );
      this.getItemCountry();
      return res.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getItemCountry = async () => {
    try {
      const res = await getItemCountryApi();
      if (res.code) throw res;
      this.itemCountry = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  updateItemCountry = async (getItemInfo) => {
    try {
      const res = await patchItemApi(getItemInfo);
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deleteItemCountry = async (deleteItemCountryId) => {
    try {
      const res = await deleteItemCountryApi(deleteItemCountryId);
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '품목 단위 삭제에 성공하였습니다',
      );
      this.getItemCountry();
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };
}

export default mesStore;
