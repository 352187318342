import { Navigate } from 'react-router-dom';
import { Loader, retryLazy } from 'src/utils/lazyUtil';

const ItemList = Loader(
  retryLazy(() => import('src/pages/mes/Item/layout/ItemList')),
);

const ItemInfor = Loader(
  retryLazy(() => import('src/pages/mes/Item/layout/ItemInfor')),
);

const mesRoutes = [
  {
    path: '',
    element: <Navigate to="item/list" replace />,
  },
  {
    path: 'item/list',
    element: <ItemList />,
  },
  {
    path: 'item/registration',
    element: <ItemInfor />,
  },
  {
    path: 'item/registration/:itemId',
    element: <ItemInfor />,
  },
];

export default mesRoutes;
